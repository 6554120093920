<template>
  <div id="legend-calendar-index">
    {{/* LEGEND */}}
    <div class="d-flex">
      <div class="calendar-legend mt-2 position-relative">
        <span class="text-info mr-3" v-on:mouseover="calendarLegend(true)" v-on:mouseleave="calendarLegend(false)">
          Légende des événements
          <b-icon class="" icon="question-circle" font-scale="1.2" variant="info"></b-icon>
        </span>

        <div class="block-calendar-legend position-absolute" v-if="legend.show">
          <div class="card">
            <div class="card-body">
              <h5>Légende des événements</h5>
              <hr />

              <h6>Evénements de centre(s) :</h6>

              <div v-for="center in CenterModule.centers" :key="center.id">
                <div class="d-flex mt-2">
                  <span class="circle-legend-color" :style="{backgroundColor: center.color}"></span>
                  <span class="ml-2">{{ center.name }}</span>
                </div>
              </div>

              <hr />

              <div class="d-flex mt-2">
                <span class="circle-legend-color legend-category"></span> <span class="ml-2">Evénements de catégorie(s)</span>
              </div>

              <hr />

              <div class="d-flex mt-2">
                <span class="circle-legend-color legend-shop"></span> <span class="ml-2">Evénements de magasin(s)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  components: {
  },
  data() {
    return {
      legend: {
        show: false
      },
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*-------------LEGEND----------------*/
    calendarLegend(isShowLegend) {
      this.legend.show = isShowLegend
    },
  },
  computed: {
    ...mapState(['CenterModule']),
  },
}
</script>
